import { Layout, Row, Col, Divider } from "antd";
import React, { useState, useEffect } from "react";
import "./App.css";
import TopNav from "./components/nav/TopNav";
import SideBar from "./components/nav/SideBar";
import ClockControls from "./components/clock/ClockControls";
import PendingTasksSection from "./components/tasks/PendingTasksSection";
import DoneTasksSection from "./components/tasks/DoneTasksSection";
import StatsLayout from "./components/stats/StatsLayout";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import UserProvider from "./utils/UserProvider";
import { useDispatch } from "react-redux";
import {
  tasksCollectionName,
  pendingCollectionName,
  statsCollectionName,
} from "./utils/firebaseConstants";
import { getYYYYMMDDToday } from "./utils/dateHelpers";
import {
  TaskCollectionFirebase,
  PendingCollectionFirebase,
  StatsCollectionFirebase,
} from "./types/stateTypes";
import { PendingEntry, Task } from "./types/types";
import { auth, db } from "./utils/firebase";
import { setPendingEntries, setDoneTasks } from "./components/tasks/tasksSlice";
import { setStats } from "./components/stats/statsSlice";

const { Content } = Layout;

function App() {
  const [collapsed, setCollapsed] = useState(true);
  const dispatch = useDispatch();

  const taskResponsiveCols = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 24,
    xxl: 12,
  };

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const pendingCollectionRef = db
          .collection(pendingCollectionName)
          .doc(user.uid);
        const tasksCollectionRef = db
          .collection(tasksCollectionName)
          .doc(user.uid);
        const statsCollectionRef = db
          .collection(statsCollectionName)
          .doc(user.uid);

        const pendingCollectionDoc: any = await pendingCollectionRef.get();
        const tasksCollectionDoc: any = await tasksCollectionRef.get();
        const statsCollectionDoc: any = await statsCollectionRef.get();

        const pendingCollectionDocData: PendingCollectionFirebase = pendingCollectionDoc.data() as PendingCollectionFirebase;
        const tasksCollectionDocData: TaskCollectionFirebase = tasksCollectionDoc.data() as TaskCollectionFirebase;
        const statsCollectionDocData: StatsCollectionFirebase = statsCollectionDoc.data() as StatsCollectionFirebase;

        if (pendingCollectionDoc.exists) {
          const pendingEntries: { [index: string]: PendingEntry } =
            pendingCollectionDocData.pendingEntries;
          const pendingEntriesOrder: string[] =
            pendingCollectionDocData.pendingEntriesOrder;
          dispatch(
            setPendingEntries({
              pendingEntries: pendingEntries,
              pendingEntriesOrder: pendingEntriesOrder,
            })
          );
        }

        if (tasksCollectionDoc.exists) {
          const yymmdd: string = getYYYYMMDDToday();
          const doneTasks: Task[] = tasksCollectionDocData[yymmdd];
          console.log(tasksCollectionDocData);
          console.log(doneTasks);
          dispatch(setDoneTasks({ doneTasks: doneTasks }));
        }

        if (statsCollectionDoc.exists) {
          dispatch(setStats({ statsData: statsCollectionDocData }));
        }
      }
    });
  }, [dispatch]);

  return (
    <UserProvider>
      <div className="App">
        <Router>
          <Layout>
            <SideBar
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            ></SideBar>
            <Layout style={{ paddingLeft: collapsed ? 80 : 250 }}>
              <TopNav collapsed={collapsed} toggle={toggle}></TopNav>
              <Switch>
                <Route exact path="/">
                  <Content className="contentWrapper">
                    <ClockControls></ClockControls>
                    <Row>
                      <Col span={1}></Col>
                      <Col span={22}>
                        <Divider></Divider>
                      </Col>
                      <Col span={1}></Col>
                    </Row>
                    <Row className="tasksRow">
                      <Col {...taskResponsiveCols}>
                        <PendingTasksSection></PendingTasksSection>
                      </Col>
                      <Col {...taskResponsiveCols}>
                        <DoneTasksSection></DoneTasksSection>
                      </Col>
                    </Row>
                  </Content>
                </Route>
                <Route path="/stats">
                  <Content className="contentWrapper">
                    <StatsLayout></StatsLayout>
                  </Content>
                </Route>
              </Switch>
            </Layout>
          </Layout>
        </Router>
      </div>
    </UserProvider>
  );
}

export default App;
