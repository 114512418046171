import { Layout, Menu, Space } from "antd";
import "./SideBar.css";
import {
  AreaChartOutlined,
  ClockCircleOutlined,
  ClockCircleFilled,
  QuestionCircleOutlined,
  CoffeeOutlined,
} from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { SideBarProps } from "../../types/propTypes";
import { useLocation } from "react-router-dom";

const { Sider } = Layout;

export default function SideBar(props: SideBarProps) {
  const location: any = useLocation();

  return (
    <Sider
      style={{
        position: "fixed",
        height: "100vh",
        overflow: "auto",
        left: 0,
      }}
      trigger={null}
      width={250}
      collapsible
      collapsed={props.collapsed}
      onCollapse={(currentlyCollapsed, type) =>
        props.setCollapsed(currentlyCollapsed)
      }
    >
      <Space
        className="logo"
        style={{
          paddingTop: props.collapsed ? 15 : 0,
          paddingBottom: props.collapsed ? 15 : 0,
          paddingLeft: props.collapsed ? 30 : 25,
        }}
      >
        <div className="clockIcon">
          <ClockCircleFilled />
        </div>
        <h2
          style={{
            display: props.collapsed ? "none" : "inline-flex",
          }}
          className="logoText"
        >
          Pomodoro Clock
        </h2>
      </Space>
      <div className="menuParent">
        <Menu
          defaultSelectedKeys={[location.pathname]}
          mode="inline"
          theme="dark"
        >
          <Menu.Item key="/" title="Clock" icon={<ClockCircleOutlined />}>
            <span>
              <Link to="/">Clock</Link>
            </span>
          </Menu.Item>
          <Menu.Item key="/stats" title="Stats" icon={<AreaChartOutlined />}>
            <span>
              <Link to="/stats">Stats</Link>
            </span>
          </Menu.Item>
        </Menu>

        <div className="helpMenu">
          <div id="feedbackButton">
            <a
              href="http://buymeacoff.ee/yjxiao"
              className="helpMenuLink"
              style={{ color: "black" }}
            >
              <CoffeeOutlined />
              <span
                style={{
                  display: props.collapsed ? "none" : "inline-flex",
                  paddingLeft: props.collapsed ? 0 : 5,
                }}
              >
                Product Feedback
              </span>
            </a>
          </div>
          <div id="supportButton">
            <a
              href="mailto: winkerxiao@gmail.com"
              className="helpMenuLink"
              style={{ color: "white" }}
            >
              <QuestionCircleOutlined />
              <span
                style={{
                  display: props.collapsed ? "none" : "inline-flex",
                  paddingLeft: props.collapsed ? 0 : 5,
                }}
              >
                Support
              </span>
            </a>
          </div>
        </div>
      </div>
    </Sider>
  );
}
