import { Drawer, Button, Form, Input, InputNumber } from "antd";
import "./TaskDrawer.css";
import { useState } from "react";
import { taskEditedThunk } from "./tasksThunks";
import { EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { PendingEntry } from "../../types/types";
import { TaskFormValues } from "../../types/propTypes";
import { EditTaskDrawerProps } from "../../types/propTypes";
import { RootState } from "../../app/store";

const { TextArea } = Input;

export default function EditTaskDrawer(props: EditTaskDrawerProps) {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const entryBeingEdited: PendingEntry = useSelector((state: RootState) => {
    return state.tasks.pendingEntries[props.entryId];
  });

  const showDrawer = (): void => {
    setVisible(true);
  };

  const onClose = (): void => {
    setVisible(false);
  };

  const onFinish = (values: TaskFormValues) => {
    dispatch(
      taskEditedThunk({
        entryId: props.entryId,
        category: values.category,
        description: values.description,
        count: values.count,
      })
    );
    onClose();
  };

  return (
    <>
      <span onClick={() => showDrawer()}>
        <EditOutlined /> Edit Task
      </span>
      <Drawer
        title="Edit Task"
        width={400}
        placement="right"
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              form="editTaskForm"
              key="submit"
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          id="editTaskForm"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            count: entryBeingEdited.count,
            category: entryBeingEdited.entryCategory,
            description: entryBeingEdited.entryDescription,
          }}
        >
          <Form.Item label="Category" name="category" required>
            <Input placeholder={entryBeingEdited.entryCategory} />
          </Form.Item>
          <Form.Item label="Description" name="description" required>
            <TextArea
              rows={3}
              placeholder={entryBeingEdited.entryDescription}
            />
          </Form.Item>
          <Form.Item label="Count" name="count" required>
            <InputNumber />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
