import { List, Typography, Col, Space, Button, Menu, Dropdown } from "antd";
import { DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import { PendingTaskProps } from "../../types/propTypes";
import React from "react";
import "./PendingTask.css";
import {
  entryDeletedThunk,
  entryCountIncreasedThunk,
  entryCountDecreasedThunk,
} from "./tasksThunks";
import EditTaskDrawer from "./EditTaskDrawer";

const { Text } = Typography;

export default function PendingTask(props: PendingTaskProps) {
  const dispatch = useDispatch();

  return (
    <Draggable
      draggableId={props.pendingEntry.entryId}
      index={props.index}
      key={props.pendingEntry.entryId}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <List.Item className="pendingTask">
            <Col span={6}>
              <Text code>{props.pendingEntry.entryCategory}</Text>
            </Col>
            <Col span={9}>
              <Text>{props.pendingEntry.entryDescription}</Text>
            </Col>
            <Col span={1} />
            <Col span={8}>
              <Space className="pendingTaskOptions" wrap>
                <Space>
                  <Button
                    type="default"
                    shape="circle"
                    size="small"
                    disabled={props.pendingEntry.count === 1}
                    icon={<MinusOutlined />}
                    onClick={(e) =>
                      dispatch(
                        entryCountDecreasedThunk({
                          entryId: props.pendingEntry.entryId,
                        })
                      )
                    }
                  />
                  <Text>{props.pendingEntry.count}</Text>
                  <Button
                    type="default"
                    shape="circle"
                    size="small"
                    disabled={props.pendingEntry.count === 99}
                    icon={<PlusOutlined />}
                    onClick={(e) =>
                      dispatch(
                        entryCountIncreasedThunk({
                          entryId: props.pendingEntry.entryId,
                        })
                      )
                    }
                  />
                </Space>
                <Dropdown.Button
                  size="small"
                  overlay={
                    <Menu>
                      <Menu.Item key="edit">
                        <EditTaskDrawer entryId={props.pendingEntry.entryId} />
                      </Menu.Item>
                      <Menu.Item
                        key="delete"
                        onClick={(e) =>
                          dispatch(
                            entryDeletedThunk({
                              entryId: props.pendingEntry.entryId,
                            })
                          )
                        }
                      >
                        <DeleteOutlined /> Delete Task
                      </Menu.Item>
                    </Menu>
                  }
                >
                  Options
                </Dropdown.Button>
              </Space>
            </Col>
          </List.Item>
        </div>
      )}
    </Draggable>
  );
}
