import { configureStore } from "@reduxjs/toolkit";
import ClockReducer from "../components/clock/clockSlice";
import TasksReducer from "../components/tasks/tasksSlice";
import StatsReducer from "../components/stats/statsSlice";

export const store = configureStore({
  reducer: {
    clock: ClockReducer,
    tasks: TasksReducer,
    stats: StatsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
