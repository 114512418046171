import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { v4 as uuidv4 } from "uuid";
import { PendingEntry, Task } from "../../types/types";
import {
  ReorderTasksPayload,
  TaskAddedPayload,
  TaskEditedPayload,
  EntryCountDecreasedPayload,
  EntryCountIncreasedPayload,
  CompleteTaskPayload,
  SetDoneTasksPayload,
  EntryDeletedPayload,
  DoneTaskDeletedPayload,
  SetPendingEntriesPayload,
} from "../../types/propTypes";
import { TaskState } from "../../types/stateTypes";

import { RootState } from "../../app/store";

const initialState: TaskState = {
  pendingEntries: {},
  done: [],
  pendingEntriesOrder: [],
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState: initialState as TaskState,
  reducers: {
    reorderTasks(state, action: PayloadAction<ReorderTasksPayload>) {
      const { destination, source, draggableId } = action.payload;

      // Insert dragged task into dropped index.
      const newPendingEntriesOrder: string[] = Array.from(
        state.pendingEntriesOrder
      );
      newPendingEntriesOrder.splice(source.index, 1);
      newPendingEntriesOrder.splice(destination.index, 0, draggableId);

      state.pendingEntriesOrder = newPendingEntriesOrder;
    },
    clearDoneTasks(state) {
      state.done = [];
    },
    setDoneTasks(state, action: PayloadAction<SetDoneTasksPayload>) {
      const { doneTasks } = action.payload;
      state.done = doneTasks;
    },
    completeCurrentTask(state, action: PayloadAction<CompleteTaskPayload>) {
      const { taskTime } = action.payload;
      const firstEntryId: string = state.pendingEntriesOrder[0];

      if (firstEntryId === undefined) {
        return;
      }

      state.pendingEntries[firstEntryId].count -= 1;

      state.done.push({
        taskId: uuidv4(),
        taskCategory: state.pendingEntries[firstEntryId].entryCategory,
        taskDescription: state.pendingEntries[firstEntryId].entryDescription,
        taskTime: taskTime,
      });

      if (state.pendingEntries[firstEntryId].count === 0) {
        delete state.pendingEntries[firstEntryId];
        state.pendingEntriesOrder = state.pendingEntriesOrder.filter(
          (entryId) => entryId !== firstEntryId
        );
      }
    },
    taskAdded(state, action: PayloadAction<TaskAddedPayload>) {
      const { category, description, count } = action.payload;
      const newEntryId: string = uuidv4();
      state.pendingEntries[newEntryId] = {
        entryId: newEntryId,
        entryDescription: description,
        entryCategory: category,
        count: count,
      };
      state.pendingEntriesOrder.push(newEntryId);
    },
    taskEdited(state, action: PayloadAction<TaskEditedPayload>) {
      const { entryId, category, description, count } = action.payload;
      state.pendingEntries[entryId].entryCategory = category;
      state.pendingEntries[entryId].entryDescription = description;
      state.pendingEntries[entryId].count = count;
    },
    doneTaskDeleted(state, action: PayloadAction<DoneTaskDeletedPayload>) {
      const { taskId } = action.payload;
      state.done = state.done.filter(
        (currentTask: Task) => currentTask.taskId !== taskId
      );
    },
    entryCountIncreased(
      state,
      action: PayloadAction<EntryCountIncreasedPayload>
    ) {
      const { entryId } = action.payload;
      state.pendingEntries[entryId].count += 1;
    },
    entryCountDecreased(
      state,
      action: PayloadAction<EntryCountDecreasedPayload>
    ) {
      const entryIdToIncrease: string = action.payload.entryId;
      state.pendingEntries[entryIdToIncrease].count -= 1;
    },
    entryDeleted(state, action: PayloadAction<EntryDeletedPayload>) {
      const { entryId } = action.payload;
      delete state.pendingEntries[entryId];
      state.pendingEntriesOrder = state.pendingEntriesOrder.filter(
        (id) => id !== entryId
      );
    },
    resetTasks(state) {
      return initialState;
    },
    setPendingEntries(state, action: PayloadAction<SetPendingEntriesPayload>) {
      const { pendingEntries, pendingEntriesOrder } = action.payload;
      state.pendingEntries = pendingEntries;
      state.pendingEntriesOrder = pendingEntriesOrder;
    },
  },
});

export const {
  reorderTasks,
  completeCurrentTask,
  taskAdded,
  taskEdited,
  clearDoneTasks,
  setDoneTasks,
  doneTaskDeleted,
  resetTasks,
  entryCountIncreased,
  entryCountDecreased,
  entryDeleted,
  setPendingEntries,
} = tasksSlice.actions;

export default tasksSlice.reducer;

export const getPendingEntriesInOrder = (state: RootState): PendingEntry[] => {
  return state.tasks.pendingEntriesOrder.map(
    (taskId: string) => state.tasks.pendingEntries[taskId]
  );
};

export const getFirstTask = (state: RootState): PendingEntry => {
  return state.tasks.pendingEntries[state.tasks.pendingEntriesOrder[0]];
};

export const getDoneTasks = (state: RootState): Task[] => {
  return state.tasks.done;
};
