import React from "react";
import Filter7 from "@material-ui/icons/Filter7";
import WbSunny from "@material-ui/icons/WbSunny";
import Today from "@material-ui/icons/Today";
import HourglassFull from "@material-ui/icons/HourglassFull";
import { Col, Row, Card, Avatar } from "antd";
import { OverviewRowProps } from "../../types/propTypes";
import {
  getYYYYMMDDToday,
  getYYYYMMDDWeek,
  getYYYYMMDDMonth,
} from "../../utils/dateHelpers";
import { getTimeString } from "./statsConstants";

const { Meta } = Card;

const topColResponsiveProps = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 6,
  style: { marginBottom: 24 },
};

const yymmddToday: string = getYYYYMMDDToday();
const yymmddWeek: string = getYYYYMMDDWeek();
const yymmddMonth: string = getYYYYMMDDMonth();

export default function OverviewRow(props: OverviewRowProps) {
  return (
    <Row gutter={24} className="overviewRow">
      <Col {...topColResponsiveProps}>
        <Card bordered={false}>
          <Meta
            avatar={
              <Avatar
                style={{ backgroundColor: "white" }}
                size="large"
                icon={<WbSunny style={{ color: "orange" }}></WbSunny>}
              />
            }
            title="Today"
            description={
              yymmddToday in props.overviewRowData.dailyStats
                ? getTimeString(
                    props.overviewRowData.dailyStats[yymmddToday]
                      .tasksCompleted,
                    props.overviewRowData.dailyStats[yymmddToday]
                      .timeSpentSeconds
                  )
                : "0 tasks completed, 0hr 0m spent"
            }
          />
        </Card>
      </Col>
      <Col {...topColResponsiveProps}>
        <Card bordered={false}>
          <Meta
            avatar={
              <Avatar
                style={{ backgroundColor: "white" }}
                size="large"
                icon={<Filter7 color="secondary"></Filter7>}
              />
            }
            title="Week"
            description={
              yymmddWeek in props.overviewRowData.weeklyStats
                ? getTimeString(
                    props.overviewRowData.weeklyStats[yymmddWeek]
                      .tasksCompleted,
                    props.overviewRowData.weeklyStats[yymmddWeek]
                      .timeSpentSeconds
                  )
                : "0 tasks completed, 0hr 0m spent"
            }
          />
        </Card>
      </Col>
      <Col {...topColResponsiveProps}>
        <Card bordered={false}>
          <Meta
            avatar={
              <Avatar
                style={{ backgroundColor: "white" }}
                size="large"
                icon={<Today color="primary"></Today>}
              />
            }
            title="Month"
            description={
              yymmddMonth in props.overviewRowData.monthlyStats
                ? getTimeString(
                    props.overviewRowData.monthlyStats[yymmddMonth]
                      .tasksCompleted,
                    props.overviewRowData.monthlyStats[yymmddMonth]
                      .timeSpentSeconds
                  )
                : "0 tasks completed, 0hr 0m spent"
            }
          />
        </Card>
      </Col>
      <Col {...topColResponsiveProps}>
        <Card bordered={false}>
          <Meta
            avatar={
              <Avatar
                style={{ backgroundColor: "white" }}
                size="large"
                icon={
                  <HourglassFull style={{ color: "green" }}></HourglassFull>
                }
              />
            }
            title="All Time"
            description={getTimeString(
              props.overviewRowData.lifeTimeStat.tasksCompleted,
              props.overviewRowData.lifeTimeStat.timeSpentSeconds
            )}
          />
        </Card>
      </Col>
    </Row>
  );
}
