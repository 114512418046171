import { Drawer, Button, Form, Input, InputNumber } from "antd";
import "./TaskDrawer.css";
import { useState } from "react";
import { taskAddedThunk } from "./tasksThunks";
import { useDispatch } from "react-redux";
import React from "react";
import { TaskFormValues } from "../../types/propTypes";

const { TextArea } = Input;

export default function NewTaskDrawer() {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onFinish = (values: TaskFormValues) => {
    dispatch(
      taskAddedThunk({
        category: values.category,
        description: values.description,
        count: values.count,
      })
    );
    onClose();
  };

  return (
    <>
      <div className="addTodoButton">
        <Button type="primary" size="large" onClick={showDrawer}>
          Add a task
        </Button>
      </div>
      <Drawer
        title="Create New Task"
        width={400}
        placement="right"
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              form="newTaskForm"
              key="submit"
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          id="newTaskForm"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{ count: 1 }}
        >
          <Form.Item label="Category" name="category" required>
            <Input placeholder="chores" />
          </Form.Item>
          <Form.Item label="Description" name="description" required>
            <TextArea rows={3} placeholder="taking out the trash" />
          </Form.Item>
          <Form.Item label="Count" name="count" required>
            <InputNumber />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
