import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  OverviewRowData,
  HeatMapData,
  CategoryChartData,
  BarChartData,
  Task,
  DateToPomodoroStats,
} from "../../types/types";
import { StatsState } from "../../types/stateTypes";
import {
  SetStatsPayload,
  StatsTaskCompletedPayload,
  StatsTasksClearedPayload,
} from "../../types/propTypes";
import { RootState } from "../../app/store";
import {
  getYYYYMMDDMonth,
  getYYYYMMDDToday,
  getYYYYMMDDWeek,
} from "../../utils/dateHelpers";
import moment from "moment";

const yymmddToday: string = getYYYYMMDDToday();
const yymmddWeek: string = getYYYYMMDDWeek();
const yymmddMonth: string = getYYYYMMDDMonth();

let heatMapMomentPointer: any = moment();
heatMapMomentPointer.subtract(209, "days");

let barChartMomentPointer: any = moment();
barChartMomentPointer.subtract(6, "days");

let initialState: StatsState = {
  heatMapData: {},
  overviewRow: {
    dailyStats: {
      [yymmddToday]: { timeSpentSeconds: 0, tasksCompleted: 0 },
    },
    weeklyStats: {
      [yymmddWeek]: { timeSpentSeconds: 0, tasksCompleted: 0 },
    },
    monthlyStats: {
      [yymmddMonth]: { timeSpentSeconds: 0, tasksCompleted: 0 },
    },
    lifeTimeStat: { timeSpentSeconds: 0, tasksCompleted: 0 },
  },
  categoryChartData: {},
  barChartData: {},
};

for (let i = 0; i < 7; i++) {
  initialState.barChartData[barChartMomentPointer.format("YYYY-MM-DD")] = {
    date: barChartMomentPointer.format("YYYY-MM-DD"),
    tasksCompleted: 0,
  };
  barChartMomentPointer.add(1, "days");
}

for (let i = 0; i < 210; i++) {
  initialState.heatMapData[heatMapMomentPointer.format("YYYY-MM-DD")] = {
    date: heatMapMomentPointer.format("YYYY-MM-DD"),
    day: heatMapMomentPointer.isoWeekday() - 1,
    month: heatMapMomentPointer.month(),
    taskCount: 0,
    week: heatMapMomentPointer.isoWeek().toString(),
  };
  heatMapMomentPointer.add(1, "days");
}

const statsSlice = createSlice({
  name: "stats",
  initialState: initialState as StatsState,
  reducers: {
    setStats(state, action: PayloadAction<SetStatsPayload>) {
      const { statsData } = action.payload;
      if (statsData.overviewRow) {
        const dailyStatsFirebase: DateToPomodoroStats = statsData.overviewRow
          .dailyStats as DateToPomodoroStats;
        const weeklyStatsFirebase: DateToPomodoroStats = statsData.overviewRow
          .weeklyStats as DateToPomodoroStats;
        const monthlyStatsFirebase: DateToPomodoroStats = statsData.overviewRow
          .monthlyStats as DateToPomodoroStats;

        Object.keys(dailyStatsFirebase)
          .filter((key) => key in state.overviewRow.dailyStats)
          .forEach(
            (key) =>
              (state.overviewRow.dailyStats[key] = dailyStatsFirebase[key])
          );
        Object.keys(weeklyStatsFirebase)
          .filter((key) => key in state.overviewRow.weeklyStats)
          .forEach(
            (key) =>
              (state.overviewRow.weeklyStats[key] = weeklyStatsFirebase[key])
          );
        Object.keys(monthlyStatsFirebase)
          .filter((key) => key in state.overviewRow.monthlyStats)
          .forEach(
            (key) =>
              (state.overviewRow.monthlyStats[key] = monthlyStatsFirebase[key])
          );

        state.overviewRow.lifeTimeStat = statsData.overviewRow.lifeTimeStat;
      }
      if (statsData.heatMapData) {
        Object.keys(statsData.heatMapData as HeatMapData)
          .filter((key) => key in state.heatMapData)
          .forEach(
            (key) => (state.heatMapData[key] = statsData.heatMapData[key])
          );
      }
      if (statsData.categoryChartData) {
        state.categoryChartData = statsData.categoryChartData;
      }
      if (statsData.barChartData) {
        Object.keys(statsData.barChartData as BarChartData)
          .filter((key) => key in state.barChartData)
          .forEach((key) => {
            state.barChartData[key] = statsData.barChartData[key];
          });
      }
    },
    resetStats(state) {
      return initialState;
    },
    statsTasksCleared(state, action: PayloadAction<StatsTasksClearedPayload>) {
      const { taskList } = action.payload;
      const totalTasksCompleted: number = taskList.length;
      const totalTimeSpent: number = taskList.reduce(
        (total: number, task: Task) => {
          return (total += task.taskTime);
        },
        0
      );
      state.overviewRow.lifeTimeStat.timeSpentSeconds -= totalTimeSpent;
      state.overviewRow.lifeTimeStat.tasksCompleted -= totalTasksCompleted;

      state.overviewRow.dailyStats[
        yymmddToday
      ].timeSpentSeconds -= totalTimeSpent;
      state.overviewRow.dailyStats[
        yymmddToday
      ].tasksCompleted -= totalTasksCompleted;

      state.overviewRow.weeklyStats[
        yymmddWeek
      ].timeSpentSeconds -= totalTimeSpent;
      state.overviewRow.weeklyStats[
        yymmddWeek
      ].tasksCompleted -= totalTasksCompleted;

      state.overviewRow.monthlyStats[
        yymmddMonth
      ].timeSpentSeconds -= totalTimeSpent;
      state.overviewRow.monthlyStats[
        yymmddMonth
      ].tasksCompleted -= totalTasksCompleted;

      state.heatMapData[
        moment().format("YYYY-MM-DD")
      ].taskCount -= totalTasksCompleted;
      state.barChartData[
        moment().format("YYYY-MM-DD")
      ].tasksCompleted -= totalTasksCompleted;

      taskList.forEach((task: Task) => {
        if (state.categoryChartData[task.taskCategory].tasksCompleted <= 1) {
          delete state.categoryChartData[task.taskCategory];
        } else {
          state.categoryChartData[task.taskCategory].tasksCompleted -= 1;
        }
      });
    },
    statsTaskCompleted(
      state,
      action: PayloadAction<StatsTaskCompletedPayload>
    ) {
      const { task } = action.payload;
      const taskCategory: string = task.taskCategory;

      // Update stats for overview row
      state.overviewRow.lifeTimeStat.tasksCompleted += 1;
      state.overviewRow.lifeTimeStat.timeSpentSeconds += task.taskTime;

      state.overviewRow.dailyStats[yymmddToday]!.tasksCompleted += 1;
      state.overviewRow.dailyStats[yymmddToday]!.timeSpentSeconds +=
        task.taskTime;

      state.overviewRow.weeklyStats[yymmddWeek]!.tasksCompleted += 1;
      state.overviewRow.weeklyStats[yymmddWeek]!.timeSpentSeconds +=
        task.taskTime;

      state.overviewRow.monthlyStats[yymmddMonth]!.tasksCompleted += 1;
      state.overviewRow.monthlyStats[yymmddMonth]!.timeSpentSeconds +=
        task.taskTime;

      // Update stats for heatmap
      if (yymmddToday in state.heatMapData) {
        state.heatMapData[yymmddToday]!.taskCount += 1;
      } else {
        state.heatMapData[yymmddToday] = {
          date: moment().format("YYYY-MM-DD"),
          day: moment().isoWeekday() - 1,
          month: moment().month(),
          taskCount: 1,
          week: moment().isoWeek().toString(),
        };
      }

      // Update category chart
      if (taskCategory in state.categoryChartData) {
        state.categoryChartData[taskCategory].tasksCompleted += 1;
      } else {
        state.categoryChartData[taskCategory] = {
          category: taskCategory,
          tasksCompleted: 1,
        };
      }

      // Update bar chart
      if (yymmddToday in state.barChartData) {
        state.barChartData[yymmddToday]!.tasksCompleted += 1;
      } else {
        state.barChartData[yymmddToday] = {
          date: yymmddToday,
          tasksCompleted: 1,
        };
      }
    },
  },
});

export const {
  setStats,
  resetStats,
  statsTaskCompleted,
  statsTasksCleared,
} = statsSlice.actions;

export default statsSlice.reducer;

export const getAllStats = (state: RootState): StatsState => {
  return state.stats;
};

export const getOverviewData = (state: RootState): OverviewRowData => {
  return state.stats.overviewRow;
};

export const getHeatMapData = (state: RootState): HeatMapData => {
  return state.stats.heatMapData;
};

export const getCategoryChartData = (state: RootState): CategoryChartData => {
  return state.stats.categoryChartData;
};

export const getBarChartData = (state: RootState): BarChartData => {
  return state.stats.barChartData;
};
