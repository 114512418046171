import moment from "moment";

export const droppableId: string = "pending-tasks";

export const getYYYYMMDDToday = (): string => {
  return moment().format("YYYY-MM-DD");
};

export const getYYYYMMDDWeek = (): string => {
  return moment().startOf("week").format("YYYY-MM-DD");
};

export const getYYYYMMDDMonth = (): string => {
  return moment().startOf("month").format("YYYY-MM-DD");
};
