import React from "react";
import { Chart, Geom, Tooltip, Coord, Label, View, Guide } from "bizcharts";
import { CategoryChartProps } from "../../types/propTypes";

const { Text } = Guide;

export default function CategoryPieChart(props: CategoryChartProps) {
  const cols = {
    percent: {
      formatter: (val: any) => {
        return val;
      },
    },
  };
  return (
    <div>
      <Chart height={400} padding={40} forceFit>
        <Coord type="theta" radius={0.8} />
        <Tooltip showTitle={false} />
        <View>
          <Coord type="polar" innerRadius={0.9} />
          <Geom
            type="interval"
            position="category*tasksCompleted"
            color="#444"
            size={[
              "category",
              function (val) {
                if (val % 3 === 0) {
                  return 4;
                } else {
                  return 0;
                }
              },
            ]}
            style={{
              stroke: "#444",
              lineWidth: 1,
            }}
          ></Geom>
          <Guide>
            <Text
              position={["50%", "50%"]}
              content="Categories"
              style={{
                fontSize: "36",
                fill: "#609064",
                textAlign: "center",
              }}
            />
          </Guide>
        </View>
        <View data={Object.values(props.categoryChartData)} scale={cols}>
          <Coord type="theta" innerRadius={0.75} />
          <Geom
            type="intervalStack"
            position="tasksCompleted"
            color={"category"}
          >
            <Label content="category" offset={40} />
          </Geom>
        </View>
      </Chart>
    </div>
  );
}
