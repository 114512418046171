import { db } from "./firebase";
import {
  StatsState,
  TaskState,
  PendingCollectionFirebase,
  TaskCollectionFirebase,
} from "../types/stateTypes";
import { getYYYYMMDDToday } from "../utils/dateHelpers";
import {
  pendingCollectionName,
  statsCollectionName,
  tasksCollectionName,
} from "./firebaseConstants";

export const updatePendingCollection = async (
  taskState: TaskState,
  userId: string
) => {
  const userRef = db.collection(pendingCollectionName).doc(userId);
  let docForFirebase: PendingCollectionFirebase = {
    pendingEntries: taskState.pendingEntries,
    pendingEntriesOrder: taskState.pendingEntriesOrder,
  };
  userRef.set(docForFirebase).catch(function (error) {
    console.log("error writing doc: ", error);
  });
};

export const updateTasksCollection = async (
  taskState: TaskState,
  userId: string
) => {
  const userRef = db.collection(tasksCollectionName).doc(userId);
  const yymmddToday: string = getYYYYMMDDToday();

  let docForFirebase: TaskCollectionFirebase = {
    [yymmddToday]: taskState.done,
  };

  userRef.set(docForFirebase).catch(function (error) {
    console.log("error writing doc: ", error);
  });
};

export const updateStatsCollection = async (
  statsState: StatsState,
  userId: string
) => {
  const userRef = db.collection(statsCollectionName).doc(userId);

  userRef.set(statsState).catch(function (error) {
    console.log("error writing doc: ", error);
  });
};

export const deleteTasksCollection = async (userId: string) => {
  const userRef = db.collection(tasksCollectionName).doc(userId);
  const userDoc = await userRef.get();

  if (!userDoc.exists) {
    return;
  }

  userRef.delete().catch(function (error) {
    console.log("error deleting doc: ", error);
  });
};
