import { Row, Card, Col } from "antd";
import "./StatsLayout.css";
import React from "react";
import TasksHeatMap from "./TasksHeatMap";
import CategoryPieChart from "./CategoryPieChart";
import TasksBarChart from "./TasksBarChart";
import OverviewRow from "./OverviewRow";
import { useSelector } from "react-redux";
import {
  CategoryChartData,
  HeatMapData,
  BarChartData,
} from "../../types/types";
import { OverviewRowData } from "../../types/types";
import {
  getOverviewData,
  getHeatMapData,
  getCategoryChartData,
  getBarChartData,
} from "./statsSlice";

const bottomColResponsiveProps = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
  xl: 12,
  style: { marginBottom: 24 },
};

export default function StatsLayout() {
  const overviewRowData: OverviewRowData = useSelector(getOverviewData);
  const heatMapData: HeatMapData = useSelector(getHeatMapData);
  const barChartData: BarChartData = useSelector(getBarChartData);
  const categoryChartData: CategoryChartData = useSelector(
    getCategoryChartData
  );

  return (
    <div className="gridContent">
      <OverviewRow overviewRowData={overviewRowData}></OverviewRow>
      <div className="activityRow">
        <Card title="Heat Map" bordered={false}>
          <TasksHeatMap heatMapData={heatMapData}></TasksHeatMap>
        </Card>
      </div>
      <Row gutter={24} className="bottomRow">
        <Col {...bottomColResponsiveProps}>
          <Card title="Breakdown by Category" bordered={false}>
            <CategoryPieChart
              categoryChartData={categoryChartData}
            ></CategoryPieChart>
          </Card>
        </Col>
        <Col {...bottomColResponsiveProps}>
          <Card title="Last 7 Days" bordered={false}>
            <TasksBarChart barChartData={barChartData}></TasksBarChart>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
