import { Mode } from "../../types/types";

export const formatTime = (baseTimeInSeconds: number): string => {
  const minute: number = Math.floor(baseTimeInSeconds / 60);
  const minuteStr: string = minute < 10 ? "0" + String(minute) : String(minute);

  const second: number = baseTimeInSeconds - 60 * minute;
  const secondStr: string = second < 10 ? "0" + String(second) : String(second);

  return `${minuteStr}:${secondStr}`;
};

export const formatBrowserTitle = (
  baseTimeInSeconds: number,
  mode: Mode
): string => {
  const timeStr: string = formatTime(baseTimeInSeconds);
  const modeStr: string = mode === Mode.Pomodoro ? "Pomodoro" : "Break";
  return timeStr + " " + modeStr;
};
