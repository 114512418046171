import React, { useState, useEffect, createContext } from "react";
import firebase from "./firebase";

type ContextProps = {
  user: firebase.User | null;
};

export const UserContext = createContext<Partial<ContextProps>>({ user: null });

export default function UserProvider(props: any) {
  const [user, setUser] = useState(null as firebase.User | null);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user: any) => {
      setUser(user);
    });
  }, []);
  return (
    <UserContext.Provider value={{ user }}>
      {props.children}
    </UserContext.Provider>
  );
}
