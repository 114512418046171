import { List, Row, Col } from "antd";
import PendingTask from "./PendingTask";
import { useSelector } from "react-redux";
import { Droppable } from "react-beautiful-dnd";
import { droppableId } from "./tasksConstants";
import { getPendingEntriesInOrder } from "./tasksSlice";
import "./PendingTask";
import { PendingEntry } from "../../types/types";
import React from "react";

export default function PendingTasksList() {
  const pendingEntries: PendingEntry[] = useSelector(getPendingEntriesInOrder);

  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          <List
            itemLayout="horizontal"
            dataSource={pendingEntries}
            header={
              <Row className="rowHeader">
                <Col span={6}>CATEGORY</Col>
                <Col span={9}>DESCRIPTION</Col>
                <Col span={1}></Col>
                <Col span={8}></Col>
              </Row>
            }
            renderItem={(pendingEntry: PendingEntry, index: number) => (
              <PendingTask pendingEntry={pendingEntry} index={index} />
            )}
          >
            {provided.placeholder}
          </List>
        </div>
      )}
    </Droppable>
  );
}
