import { Layout } from "antd";
import "./TopNav.css";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import React from "react";
import LoginMenu from "./LoginMenu";

const { Header } = Layout;

export default function TopNav(props: any) {
  return (
    <Header
      className="siteHeader"
      style={{
        position: "fixed",
        zIndex: 100,
        width: "100%",
        paddingTop: 0,
        paddingLeft: 0,
        paddingBottom: 0,
        paddingRight: props.collapsed ? 80 : 250,
      }}
    >
      {React.createElement(
        props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
        {
          className: "trigger",
          onClick: props.toggle,
        }
      )}
      <LoginMenu></LoginMenu>
    </Header>
  );
}
