import { ThunkDispatch, ThunkAction } from "@reduxjs/toolkit";
import { taskCompletedThunk } from "../tasks/tasksThunks";
import { Mode } from "../../types/types";
import { AnyAction } from "redux";
import { RootState } from "../../app/store";
import {
  clockTicked,
  clockStarted,
  modeReset,
  clockPaused,
} from "./clockSlice";

export function clockStartedThunk(): ThunkAction<
  void,
  RootState,
  {},
  AnyAction
> {
  return (dispatch: ThunkDispatch<RootState, {}, AnyAction>, getState: any) => {
    let { timerId } = getState().clock;
    if (timerId == null) {
      timerId = setInterval(
        () => {
          dispatch(clockTicked());
          dispatch(phaseControl(timerId));
        },
        process.env.NODE_ENV === "production" ? 1000 : 10
      );
      dispatch(clockStarted({ timerId: timerId }));
    }
  };
}

export function phaseControl(
  timerId: number | null
): ThunkAction<void, RootState, {}, AnyAction> {
  return (dispatch: ThunkDispatch<RootState, {}, AnyAction>, getState: any) => {
    const { baseTimeInSeconds, mode } = getState().clock;
    if (baseTimeInSeconds === -1) {
      if (mode === Mode.Pomodoro) {
        clearInterval(timerId!);
        dispatch(taskCompletedThunk());
        dispatch(modeReset({ timerId: null, mode: Mode.ShortBreak }));
      }
      if (mode === Mode.ShortBreak || mode === Mode.LongBreak) {
        clearInterval(timerId!);
        dispatch(modeReset({ timerId: null, mode: Mode.Pomodoro }));
      }
    }
  };
}

export function clockPausedThunk(): ThunkAction<
  void,
  RootState,
  {},
  AnyAction
> {
  return (dispatch: ThunkDispatch<RootState, {}, AnyAction>, getState: any) => {
    const { timerId } = getState().clock;
    if (timerId != null) {
      clearInterval(timerId);
      dispatch(clockPaused({ timerId: null }));
    }
  };
}

export function clockResetToModeThunk(
  mode: Mode
): ThunkAction<void, RootState, {}, AnyAction> {
  return (dispatch: ThunkDispatch<RootState, {}, AnyAction>, getState: any) => {
    const { timerId } = getState().clock;
    if (timerId != null) {
      clearInterval(timerId);
    }
    dispatch(modeReset({ mode: mode, timerId: null }));
  };
}
