import { Layout, Row, Button, Radio, Space, Modal } from "antd";
import ClockSettingsDrawer from "./ClockSettingsDrawer";
import Clock from "./Clock";
import { useDispatch, useSelector } from "react-redux";
import { getBaseTime, getActiveModeBaseTime } from "./clockSlice";
import {
  clockStartedThunk,
  clockPausedThunk,
  clockResetToModeThunk,
} from "./clockThunks";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React from "react";
import "./ClockControls.css";
import { Mode } from "../../types/types";
import { RootState } from "../../app/store";

const { Content } = Layout;
const { confirm } = Modal;

export default function ClockControls() {
  const dispatch = useDispatch();

  const currentMode = useSelector((state: RootState) => {
    return state.clock.mode;
  });

  const shouldEnableButtons = useSelector((state: RootState) => {
    return state.tasks.pendingEntriesOrder.length! > 0;
  });

  const isRunning = useSelector((state: RootState) => {
    return state.clock.isRunning;
  });

  const baseTime = useSelector(getBaseTime);

  const activeModeBaseTime = useSelector(getActiveModeBaseTime);

  function changeMode(mode: Mode): void {
    dispatch(clockResetToModeThunk(mode));
  }

  function changeModeWithTimeRemaining(mode: Mode): void {
    dispatch(clockPausedThunk());
    confirm({
      title: "Clock Settings",
      icon: <ExclamationCircleOutlined />,
      content:
        "You currently still have some time left on the clock. Switching modes will reset that time. Do you wish to continue?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        changeMode(mode);
      },
      onCancel() {},
    });
  }

  return (
    <Row>
      <Content className="countdownWrapper">
        <Space direction="vertical" size="middle">
          <Radio.Group
            value={currentMode}
            buttonStyle="solid"
            className="modeNav"
            size="large"
            onChange={(e) =>
              baseTime < activeModeBaseTime
                ? changeModeWithTimeRemaining(e.target.value)
                : changeMode(e.target.value)
            }
          >
            <Radio.Button id="pomodoroRadio" value={Mode.Pomodoro}>
              Pomodoro
            </Radio.Button>
            <Radio.Button id="shortBreakRadio" value={Mode.ShortBreak}>
              Short Break
            </Radio.Button>
            <Radio.Button id="longBreakRadio" value={Mode.LongBreak}>
              Long Break
            </Radio.Button>
          </Radio.Group>
          <Clock></Clock>
          <Space size="large" className="controlButtons">
            <Button
              size="large"
              disabled={!shouldEnableButtons}
              id="startPauseButton"
              onClick={(e) =>
                !isRunning
                  ? dispatch(clockStartedThunk())
                  : dispatch(clockPausedThunk())
              }
            >
              {isRunning ? "Pause" : "Start"}
            </Button>
            <Button
              size="large"
              id="resetButton"
              disabled={!shouldEnableButtons}
              onClick={(e) => dispatch(clockResetToModeThunk(currentMode))}
            >
              Reset
            </Button>
            <ClockSettingsDrawer></ClockSettingsDrawer>
          </Space>
        </Space>
      </Content>
    </Row>
  );
}
