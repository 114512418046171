// Stats related types
export interface BarChartData {
  // Indexed by string date in format YYYY-MM-DD
  [date: string]: BarChartEntry;
}

export interface BarChartEntry {
  date: string;
  tasksCompleted: number;
}

export interface CategoryChartData {
  // Indexed by the category string
  [category: string]: CategoryChartEntry;
}

export interface CategoryChartEntry {
  category: string;
  tasksCompleted: number;
}

export interface HeatMapData {
  [index: string]: HeatMapEntry;
}

export interface HeatMapEntry {
  // Date of entry, in format YYYY-MM-DD
  date: string;
  // Zero indexed day of the week
  day: number;
  // Zero indexed month
  month: number;
  taskCount: number;
  // ISO week of the year
  week: string;
}

export class OverviewRowData {
  dailyStats: DateToPomodoroStats;
  weeklyStats: DateToPomodoroStats;
  monthlyStats: DateToPomodoroStats;
  lifeTimeStat: PomodoroStats;
}

export interface DateToPomodoroStats {
  // Indexed by date in the form YYYY-MM-DD
  [date: string]: PomodoroStats;
}

export interface PomodoroStats {
  timeSpentSeconds: number;
  tasksCompleted: number;
}

// Clock related types
export enum Mode {
  Pomodoro,
  ShortBreak,
  LongBreak,
}

// Task related types
export interface PendingEntry {
  entryId: string;
  entryDescription: string;
  entryCategory: string;
  count: number;
}

export interface Task {
  taskId: string;
  taskDescription: string;
  taskCategory: string;
  taskTime: number;
}
