import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import { BarChartProps } from "../../types/propTypes";

export default function TasksBarChart(props: BarChartProps) {
  const cols = {
    tasksCompleted: { tickInterval: 2 },
  };

  return (
    <div>
      <Chart
        height={400}
        padding="auto"
        data={Object.values(props.barChartData)}
        scale={cols}
        forceFit
      >
        <Axis name="date" />
        <Axis name="tasksCompleted" />
        <Tooltip
          crosshairs={{
            type: "y",
          }}
        />
        <Geom type="interval" position="date*tasksCompleted" />
      </Chart>
    </div>
  );
}
