import { Drawer, Button, Form, InputNumber, Modal } from "antd";
import { useSelector } from "react-redux";
import { useState } from "react";
import { SettingOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import React from "react";
import { RootState } from "../../app/store";
import { ClockSettingsFormValues } from "../../types/propTypes";
import { ClockState } from "../../types/stateTypes";
import {
  clockSettingsUpdated,
  getBaseTime,
  getIsClockRunning,
  getActiveModeBaseTime,
} from "./clockSlice";

const { confirm } = Modal;

export default function ClockSettingsDrawer() {
  const dispatch = useDispatch();
  const isClockRunning = useSelector(getIsClockRunning);
  const baseTime = useSelector(getBaseTime);
  const activeModeBaseTime = useSelector(getActiveModeBaseTime);

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  function onFinishWithUsedClock(values: ClockSettingsFormValues) {
    confirm({
      title: "Clock Settings",
      icon: <ExclamationCircleOutlined />,
      content:
        "You currently still have some time left on your pomodoro/break. Changing clock settings will reset that time. Do you wish to continue?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onFinish(values);
      },
      onCancel() {},
    });
  }

  const onFinish = (values: ClockSettingsFormValues) => {
    dispatch(clockSettingsUpdated(values));
    onClose();
  };

  const clockState: ClockState = useSelector((state: RootState) => {
    return state.clock;
  });

  return (
    <>
      <Button
        size="large"
        onClick={showDrawer}
        disabled={isClockRunning}
        icon={<SettingOutlined />}
      ></Button>
      <Drawer
        title="Settings"
        width={400}
        placement="right"
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              form="clockDurationForm"
              key="submit"
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          id="clockDurationForm"
          layout="vertical"
          onFinish={
            baseTime < activeModeBaseTime ? onFinishWithUsedClock : onFinish
          }
          initialValues={{
            workDurationMinutes: clockState.pomodoroInSeconds / 60,
            shortBreakDurationMinutes: clockState.shortBreakInSeconds / 60,
            longBreakDurationMinutes: clockState.longBreakInSeconds / 60,
          }}
        >
          <Form.Item
            label="Work Duration (Minutes)"
            name="workDurationMinutes"
            required
          >
            <InputNumber min={1} max={60} />
          </Form.Item>
          <Form.Item
            label="Short Break Duration (Minutes)"
            name="shortBreakDurationMinutes"
            required
          >
            <InputNumber min={1} max={60} />
          </Form.Item>
          <Form.Item
            label="Long Break Duration (Minutes)"
            name="longBreakDurationMinutes"
            required
          >
            <InputNumber min={1} max={60} />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
