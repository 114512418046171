import { Space, Progress } from "antd";
import { useSelector } from "react-redux";
import { formatTime } from "./clockUtils";
import { getActiveModeBaseTime } from "./clockSlice";
import ClockDisplay from "./ClockDisplay";
import { RootState } from "../../app/store";
import React from "react";
import "./Clock.css";

export default function Clock() {
  const time = useSelector((state: RootState) => {
    return state.clock.baseTimeInSeconds;
  });

  const activeModeBaseTime: number = useSelector(getActiveModeBaseTime);

  return (
    <Progress
      type={"circle"}
      percent={(time / activeModeBaseTime) * 100}
      width={300}
      strokeColor={"#1790ff"}
      trailColor={"white"}
      strokeWidth={2}
      format={() => (
        <Space
          direction="vertical"
          size="small"
          className="clockDisplayContainer"
        >
          <div id="timeText">{formatTime(time)}</div>
          <ClockDisplay />
        </Space>
      )}
    ></Progress>
  );
}
