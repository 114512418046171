import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import {
  updatePendingCollection,
  deleteTasksCollection,
  updateTasksCollection,
  updateStatsCollection,
} from "../../utils/firebaseDao";
import _ from "lodash";
import { auth } from "../../utils/firebase";
import type { DropResult } from "react-beautiful-dnd";
import { PendingEntry, Task } from "../../types/types";
import {
  TaskFormValues,
  DoneTaskDeletedPayload,
  TaskEditedPayload,
  EntryDeletedPayload,
  EntryCountIncreasedPayload,
  EntryCountDecreasedPayload,
} from "../../types/propTypes";
import {
  completeCurrentTask,
  taskAdded,
  doneTaskDeleted,
  reorderTasks,
  taskEdited,
  clearDoneTasks,
  entryDeleted,
  entryCountIncreased,
  entryCountDecreased,
} from "./tasksSlice";
import { statsTaskCompleted, statsTasksCleared } from "../stats/statsSlice";
import { v4 as uuidv4 } from "uuid";

import { RootState } from "../../app/store";

export function taskCompletedThunk(): ThunkAction<
  void,
  RootState,
  {},
  AnyAction
> {
  return (dispatch: ThunkDispatch<RootState, {}, AnyAction>, getState: any) => {
    // Record done task in firebase.
    auth.onAuthStateChanged(async (user) => {
      document.title = "Pomodoro Clock";
      if (user && !_.isEmpty(getState().tasks.pendingEntries)) {
        const firstEntryId: string = getState().tasks.pendingEntriesOrder[0];
        const firstEntry: PendingEntry = getState().tasks.pendingEntries[
          firstEntryId
        ];

        const taskCompleted: Task = {
          taskId: uuidv4(),
          taskCategory: firstEntry.entryCategory,
          taskDescription: firstEntry.entryDescription,
          taskTime: getState().clock.pomodoroInSeconds,
        };
        dispatch(statsTaskCompleted({ task: taskCompleted }));
      }
      dispatch(
        completeCurrentTask({ taskTime: getState().clock.pomodoroInSeconds })
      );
      if (user) {
        updateStatsCollection(getState().stats, user.uid);
        updateTasksCollection(getState().tasks, user.uid);
        updatePendingCollection(getState().tasks, user.uid);
      }
    });
  };
}

export function taskAddedThunk({
  category,
  description,
  count,
}: TaskFormValues): ThunkAction<void, RootState, {}, AnyAction> {
  return (dispatch: ThunkDispatch<RootState, {}, AnyAction>, getState: any) => {
    auth.onAuthStateChanged(async (user) => {
      dispatch(
        taskAdded({
          category: category,
          description: description,
          count: count,
        })
      );
      if (user) {
        updatePendingCollection(getState().tasks, user.uid);
      }
    });
  };
}

export function doneTaskDeletedThunk({
  taskId,
}: DoneTaskDeletedPayload): ThunkAction<void, RootState, {}, AnyAction> {
  return (dispatch: ThunkDispatch<RootState, {}, AnyAction>, getState: any) => {
    auth.onAuthStateChanged(async (user) => {
      dispatch(doneTaskDeleted({ taskId: taskId }));
    });
  };
}

export function clearDoneTasksThunk(): ThunkAction<
  void,
  RootState,
  {},
  AnyAction
> {
  return (dispatch: ThunkDispatch<RootState, {}, AnyAction>, getState: any) => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        deleteTasksCollection(user.uid);
        dispatch(statsTasksCleared({ taskList: getState().tasks.done }));
        updateStatsCollection(getState().stats, user.uid);
      }
      dispatch(clearDoneTasks());
    });
  };
}

export function entryDeletedThunk({
  entryId,
}: EntryDeletedPayload): ThunkAction<void, RootState, {}, AnyAction> {
  return (dispatch: ThunkDispatch<RootState, {}, AnyAction>, getState: any) => {
    auth.onAuthStateChanged(async (user) => {
      dispatch(entryDeleted({ entryId: entryId }));
      if (user) {
        updatePendingCollection(getState().tasks, user.uid);
      }
    });
  };
}

export function entryCountIncreasedThunk({
  entryId,
}: EntryCountIncreasedPayload): ThunkAction<void, RootState, {}, AnyAction> {
  return (dispatch: ThunkDispatch<RootState, {}, AnyAction>, getState: any) => {
    auth.onAuthStateChanged(async (user) => {
      dispatch(entryCountIncreased({ entryId: entryId }));
      if (user) {
        updatePendingCollection(getState().tasks, user.uid);
      }
    });
  };
}

export function entryCountDecreasedThunk({
  entryId,
}: EntryCountDecreasedPayload): ThunkAction<void, RootState, {}, AnyAction> {
  return (dispatch: ThunkDispatch<RootState, {}, AnyAction>, getState: any) => {
    auth.onAuthStateChanged(async (user) => {
      dispatch(entryCountDecreased({ entryId: entryId }));
      if (user) {
        updatePendingCollection(getState().tasks, user.uid);
      }
    });
  };
}

export function taskEditedThunk({
  entryId,
  category,
  description,
  count,
}: TaskEditedPayload): ThunkAction<void, RootState, {}, AnyAction> {
  return (dispatch: ThunkDispatch<RootState, {}, AnyAction>, getState: any) => {
    auth.onAuthStateChanged(async (user) => {
      dispatch(
        taskEdited({
          category: category,
          description: description,
          count: count,
          entryId: entryId,
        })
      );
      if (user) {
        updatePendingCollection(getState().tasks, user.uid);
      }
    });
  };
}

export function dragEndedThunk(
  result: DropResult
): ThunkAction<void, RootState, {}, AnyAction> {
  return (dispatch: ThunkDispatch<RootState, {}, AnyAction>, getState: any) => {
    auth.onAuthStateChanged(async (user) => {
      const { destination, source, draggableId } = result;

      // Dragged task dropped outside of designated drop area.
      if (!destination) {
        return;
      }

      // Dragged task dropped in same place
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      dispatch(
        reorderTasks({
          destination: destination,
          source: source,
          draggableId: draggableId,
        })
      );
      if (user) {
        updatePendingCollection(getState().tasks, user.uid);
      }
    });
  };
}
