import { useSelector } from "react-redux";
import { getActiveMode } from "./clockSlice";
import { Mode, PendingEntry } from "../../types/types";
import { getFirstTask } from "../tasks/tasksSlice";
import React from "react";
import "./ClockDisplay.css";

export default function ClockDisplay() {
  const activeMode: Mode = useSelector(getActiveMode);
  const currentEntry: PendingEntry = useSelector(getFirstTask);

  if (activeMode === Mode.Pomodoro) {
    if (currentEntry === undefined) {
      return <span className="taskText">N/A</span>;
    }
    return <span className="taskText">{currentEntry.entryCategory}</span>;
  } else {
    return <span className="taskText">Break</span>;
  }
}
