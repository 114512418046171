import { Row, Col } from "antd";
import "./PendingTasksSection.css";
import PendingTasksList from "./PendingTasksList";
import { DragDropContext } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { dragEndedThunk } from "./tasksThunks";
import NewTaskDrawer from "./NewTaskDrawer";
import React from "react";
import type { DropResult } from "react-beautiful-dnd";

export default function PendingTasksSection() {
  const dispatch = useDispatch();

  function onDragEnd(result: DropResult) {
    dispatch(dragEndedThunk(result));
  }

  return (
    <Row className="todoWrapper">
      <Col span={1}></Col>
      <Col span={22}>
        <Row className="pendingTaskHeader">
          <Col span={6}>
            <h3>Pending Tasks</h3>
          </Col>
          <Col span={14}></Col>
          <Col span={4} className="newTaskButtonCol">
            <NewTaskDrawer></NewTaskDrawer>
          </Col>
        </Row>

        <DragDropContext onDragEnd={onDragEnd}>
          {<PendingTasksList></PendingTasksList>}
        </DragDropContext>
      </Col>
      <Col span={1}></Col>
    </Row>
  );
}
