import { Chart, Geom, Axis, Tooltip, Shape, Coord, Util } from "bizcharts";
import { HeatMapProps } from "../../types/propTypes";
import { HeatMapEntry } from "../../types/types";
import "./TasksHeatMap.css";

export default function HeatMap(props: HeatMapProps) {
  Shape.registerShape!("polygon", "boundary-polygon", {
    draw(cfg: any, container: any) {
      if (!Util.isEmpty(cfg.points)) {
        const attrs = {
          stroke: "#ffffff",
          lineWidth: 2,
          fill: cfg.color,
          fillOpacity: cfg.opacity,
          path: "",
        };
        const points = cfg.points;
        const path = [
          ["M", points[0].x, points[0].y],
          ["L", points[1].x, points[1].y],
          ["L", points[2].x, points[2].y],
          ["L", points[3].x, points[3].y],
        ];
        attrs.path = this.parsePath(path);
        const polygon = container.addShape("path", {
          attrs,
        });

        container.sort();
        return polygon;
      }
    },
  } as any);

  const cols = {
    day: {
      type: "cat",
      values: ["MON ", "TUES ", "WED ", "THUR ", "FRI ", "SAT ", "SUN "],
    },
    week: {
      type: "cat",
    },
    taskCount: {
      sync: true,
    },
  };

  function heatMapEntryComparator(a: HeatMapEntry, b: HeatMapEntry) {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 11;
    }
    return 0;
  }

  return (
    <div id="heatMapWrapper">
      <Chart
        height={600}
        data={Object.values(props.heatMapData).sort(heatMapEntryComparator)}
        scale={cols}
        forceFit
      >
        <Tooltip title="date" />
        <Axis
          name="week"
          position="top"
          tickLine={null}
          line={null}
          label={{
            offset: 12,
            textStyle: {
              fontSize: 12,
              fill: "#666",
              textBaseline: "top",
            },
            formatter: (val) => {
              if (val === "2") {
                return "JAN";
              } else if (val === "6") {
                return "FEB";
              } else if (val === "10") {
                return "MAR";
              } else if (val === "15") {
                return "APR";
              } else if (val === "19") {
                return "MAY";
              } else if (val === "24") {
                return "JUN";
              } else if (val === "28") {
                return "JUL";
              } else if (val === "33") {
                return "AUG";
              } else if (val === "37") {
                return "SEP";
              } else if (val === "42") {
                return "OCT";
              } else if (val === "46") {
                return "NOV";
              } else if (val === "51") {
                return "DEC";
              }
              return "";
            },
          }}
        />
        <Axis name="day" grid={null} />
        <Geom
          type="polygon"
          position="week*day*date"
          shape="boundary-polygon"
          color={["taskCount", "#BAE7FF-#1890FF-#0050B3"]}
        />
        <Coord reflect="y" />
      </Chart>
    </div>
  );
}
