import { Row, Col, Typography, Button, List, Menu, Dropdown } from "antd";
import "./DoneTasksSection.css";
import { getDoneTasks } from "./tasksSlice";
import { clearDoneTasksThunk, doneTaskDeletedThunk } from "./tasksThunks";
import { useSelector, useDispatch } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";
import React from "react";
import { Task } from "../../types/types";

const { Text } = Typography;

export default function DoneTasksSection() {
  const dispatch = useDispatch();
  const doneTasks = useSelector(getDoneTasks);

  return (
    <Row>
      <Col span={1}></Col>
      <Col span={22} className="doneWrapper">
        <Row>
          <Col span={6}>
            <h3>Done Tasks</h3>
          </Col>
          <Col span={14}></Col>
          <Col span={4} className="clearListButtonCol">
            <Button
              type="primary"
              size="large"
              onClick={(e) => dispatch(clearDoneTasksThunk())}
            >
              Clear List
            </Button>
          </Col>
        </Row>
        <div className="doneList">
          <List
            itemLayout="horizontal"
            dataSource={doneTasks}
            header={
              <Row className="rowHeader">
                <Col span={4}>CATEGORY</Col>
                <Col span={17}>DESCRIPTION</Col>
                <Col span={3}></Col>
              </Row>
            }
            renderItem={(task: Task) => (
              <List.Item className="doneTaskItem">
                <Col span={4}>
                  <Text code>{task.taskCategory}</Text>
                </Col>
                <Col span={16}>
                  <Text>{task.taskDescription}</Text>
                </Col>
                <Col span={4} className="deleteCol">
                  <Dropdown.Button
                    size="small"
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="delete"
                          onClick={(e) =>
                            dispatch(
                              doneTaskDeletedThunk({ taskId: task.taskId })
                            )
                          }
                        >
                          <DeleteOutlined /> Delete Task
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    Options
                  </Dropdown.Button>
                </Col>
              </List.Item>
            )}
          />
        </div>
      </Col>
      <Col span={1}></Col>
    </Row>
  );
}
