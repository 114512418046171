export const getTimeString = (
  tasksCompleted: number,
  timeSpentSeconds: number
) => {
  const hours: number = Math.floor(timeSpentSeconds / 3600);
  const minutes: number = Math.floor((timeSpentSeconds % 3600) / 60);

  const timeString: String = `${hours}hr ${minutes}m`;

  return (
    tasksCompleted.toString() + " tasks completed, " + timeString + " spent"
  );
};
