import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Mode } from "../../types/types";
import { ClockState } from "../../types/stateTypes";
import {
  ClockStartedOrPausedPayload,
  ClockSettingsFormValues,
  ModeResetPayload,
} from "../../types/propTypes";
import { RootState } from "../../app/store";
import { formatBrowserTitle } from "./clockUtils";

const initialState: ClockState = {
  shortBreakInSeconds: 5 * 60,
  longBreakInSeconds: 25 * 60,
  pomodoroInSeconds: process.env.NODE_ENV === "production" ? 25 * 60 : 1 * 60,
  baseTimeInSeconds: process.env.NODE_ENV === "production" ? 25 * 60 : 1 * 60,
  isRunning: false,
  mode: Mode.Pomodoro,
  timerId: null,
};

const clockSlice = createSlice({
  name: "clock",
  initialState: initialState as ClockState,
  reducers: {
    clockTicked(state) {
      state.baseTimeInSeconds -= 1;
      document.title = formatBrowserTitle(state.baseTimeInSeconds, state.mode);
    },
    clockStarted(state, action: PayloadAction<ClockStartedOrPausedPayload>) {
      const { timerId } = action.payload;
      state.timerId = timerId;
      state.isRunning = true;
    },
    clockPaused(state, action: PayloadAction<ClockStartedOrPausedPayload>) {
      const { timerId } = action.payload;
      state.timerId = timerId;
      state.isRunning = false;
    },
    clockSettingsUpdated(
      state,
      action: PayloadAction<ClockSettingsFormValues>
    ) {
      const {
        workDurationMinutes,
        shortBreakDurationMinutes,
        longBreakDurationMinutes,
      } = action.payload;

      state.pomodoroInSeconds = workDurationMinutes * 60;
      state.shortBreakInSeconds = shortBreakDurationMinutes * 60;
      state.longBreakInSeconds = longBreakDurationMinutes * 60;

      switch (state.mode) {
        case Mode.Pomodoro:
          state.baseTimeInSeconds = state.pomodoroInSeconds;
          break;
        case Mode.ShortBreak:
          state.baseTimeInSeconds = state.shortBreakInSeconds;
          break;
        case Mode.LongBreak:
          state.baseTimeInSeconds = state.longBreakInSeconds;
          break;
      }
    },
    modeReset(state, action: PayloadAction<ModeResetPayload>) {
      document.title = "Pomodoro Clock";
      const { mode, timerId } = action.payload;
      state.timerId = timerId;
      state.mode = mode;
      state.isRunning = false;
      switch (mode) {
        case Mode.Pomodoro:
          state.baseTimeInSeconds = state.pomodoroInSeconds;
          break;
        case Mode.ShortBreak:
          state.baseTimeInSeconds = state.shortBreakInSeconds;
          break;
        case Mode.LongBreak:
          state.baseTimeInSeconds = state.longBreakInSeconds;
          break;
      }
    },
  },
});

export const {
  clockTicked,
  clockStarted,
  clockPaused,
  clockSettingsUpdated,
  modeReset,
} = clockSlice.actions;

export default clockSlice.reducer;

export const getActiveModeBaseTime = (state: RootState): number => {
  switch (state.clock.mode) {
    case Mode.Pomodoro:
      return state.clock.pomodoroInSeconds;
    case Mode.ShortBreak:
      return state.clock.shortBreakInSeconds;
    case Mode.LongBreak:
      return state.clock.longBreakInSeconds;
    default:
      return 0;
  }
};

export const getActiveMode = (state: RootState): Mode => {
  return state.clock.mode;
};

export const getIsClockRunning = (state: RootState): boolean => {
  return state.clock.isRunning;
};

export const getBaseTime = (state: RootState): number => {
  return state.clock.baseTimeInSeconds;
};
