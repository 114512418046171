import React, { useContext } from "react";
import { Dropdown, Menu, Button } from "antd";
import { DownOutlined, GoogleOutlined } from "@ant-design/icons";
import { signInWithGoogle, logOut } from "../../utils/firebase";
import { useDispatch } from "react-redux";
import { resetStats } from "../stats/statsSlice";
import { resetTasks } from "../tasks/tasksSlice";
import "./LoginMenu.css";
import { UserContext } from "../../utils/UserProvider";

const LoginMenu = () => {
  const dispatch = useDispatch();
  const user = useContext(UserContext);

  const signInMenu = (
    <Menu theme="light">
      <Menu.Item>
        <Button
          size="large"
          className="login-provider-button"
          onClick={signInWithGoogle}
        >
          <GoogleOutlined />
          <span className="loginText"> Login with Google</span>
        </Button>
      </Menu.Item>
    </Menu>
  );

  const signOutMenu = (
    <Menu theme="light">
      <Menu.Item>
        <div
          className="logoutText"
          onClick={() => {
            dispatch(resetStats());
            dispatch(resetTasks());
            logOut();
          }}
        >
          Logout
        </div>
      </Menu.Item>
    </Menu>
  );

  if (!user.user) {
    return (
      <Dropdown
        overlay={signInMenu}
        placement="bottomRight"
        className="signInDropdown"
        overlayStyle={{
          paddingRight: 10,
          paddingTop: 10,
        }}
      >
        <span className="loginText" onClick={(e) => e.preventDefault()}>
          Log In <DownOutlined />
        </span>
      </Dropdown>
    );
  }

  return (
    <Dropdown
      overlay={signOutMenu}
      placement="bottomRight"
      className="signOutDropdown"
      overlayStyle={{ paddingRight: 10, paddingTop: 10 }}
    >
      <span className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        {user.user?.email} <DownOutlined />
      </span>
    </Dropdown>
  );
};

export default LoginMenu;
